<template>
    <div class="patent">
       <div class="patent-box" :style="{height:screenHeight + 'px'}">
            <div class="patent-text">
                <div class="patent-title" :style="{marginTop:screenHeight*0.14+'px'}">
                    <span>{{date.Maintitle}}</span>
                </div>
                <div class="patent-subtitle">
                    <span>{{date.Subtitle}}</span>
                </div>
                <div class="patent-line"></div>
                <div class="patent-content">
                    <div v-html="date.PContent"></div>
                </div>
                <div class="patent-img">
                    <div v-for="(item,i) in date.bannerList" :key="i">
                        <img :src="item.PImage" alt="">
                    </div>
                </div>
            </div>
       </div>
    </div>
</template>
<script>
    import { ShowPortalInfo } from "../api/home.js";
    export default {
        name: "patent",
        data(){
            return{
                screenHeight: document.documentElement.clientHeight-120,
                Height: ((document.documentElement.clientHeight-120)*0.8)+'px',
                date:{},
            }
        },
        mounted(){
            this.Info()
        },
        methods:{
            Info(){
                let params=`pType=专利版权`
                ShowPortalInfo(params).then(res=>{
                    this.date = res.Data[0]
                    let arr = this.date.PImage.split(',')
                    this.date.bannerList = arr.map(item=>{
                        return {PImage:item}
                    })
                })
            },
        },
    }
</script>
<style scoped lang="scss">
    .patent {
        overflow-y: auto;
        width: 100%;
        &-box{
            background-image: url('../assets/img/meigui.png');
            background-size: 80%;
            background-repeat: no-repeat;
            background-position: 50%;
            width: 1103px;
            margin: 0 auto;
            height: 100%;
            display: flex;
        }
        &-text{
            width: 100%;
            height: 100%;
        }
        &-img{
            width: 1103px;
            display: flex;
            flex-wrap: wrap; // 强制换行
            justify-content: space-around;
            margin-top: 100px;
            div{
                margin-right: 20px;
                margin-top: 20px;
            }
            img{
                width: 345px;
                height: 500px;
            }
        }
        &-title{
            font-size: 45px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #2A2A3E;
        }
        &-subtitle{
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4F4F6C;
            margin-top: 20px;
        }
        &-line{
            margin-top: 50px;
            width: 80px;
            height: 5px;
            background: #0505AE;
            border-radius: 5px;
        }
        &-content{
            margin-top: 50px;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000;
            letter-spacing: 2px;
            font-family: '微软雅黑';
            text-indent:2em;
        }
    }
    .patent::-webkit-scrollbar { width: 0 !important }
    .patent { -ms-overflow-style: none; }
    .patent { overflow: -moz-scrollbars-none; }
</style>